<template>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Proformas</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Proformas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Proformas
          <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtproformas"
        :value="proformas"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :filters="buscar"
        :loading="cargando"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Proformas"
        responsiveLayout="scroll"
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="numero" header="Nº PROFORMA"> </Column>
        <Column field="cliente" header="CLIENTE"> </Column>
        <Column field="fecha" header="FECHA"> </Column>
        <Column field="vendedor" header="VENDEDOR"> </Column>
        <Column field="total" header="TOTAL" class="text-right"> </Column>
        <Column field="valido_hasta" header="VALIDO HASTA"> </Column>
        <Column field="observacion" header="OBSERVACIÓN"> </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            ></Button>
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            ></Menu>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import { useAuth } from "@/stores";
export default {
  data() {
    return {
      proformas: [],
      totalRegistros: 0,
      cargando: false,
      buscar: {
        global: { matchMode: FilterMatchMode.CONTAINS, value: null },
      },
      claseEstado: ["status-outofstock", "status-instock", "status-lowstock"],
    };
  },
  ventaService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarProformas();
  },
  methods: {
    acciones(datos) {
      return [
        {
          label: "Ver Detalle",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Proforma Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/proforma/${datos.id}/detalle`,
        },
        {
          label: "Descargar PDF",
          disabled:
            "Proforma DescargarPDF" in this.auth.user.permissions
              ? false
              : true,
          icon: "pi pi-fw pi-file-pdf",
          command: () => this.imprimirProforma(datos.id),
        },
        {
          label: "Anular Proforma",
          icon: "pi pi-fw pi-trash",
          disabled:
            datos.estado == 0
              ? true
              : false || "Proforma Anular" in this.auth.user.permissions
              ? false
              : true,
          command: () => this.deleteProforma(datos.id),
        },
      ];
    },
    deleteProforma(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea anular la Proforma?",
        header: "Anular Proforma",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "SI, ANULAR",
        rejectLabel: "CANCELAR",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.ventaService.anularProforma(id).then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Proforma Anulada",
              detail: response.mensaje,
              life: 3000,
            });
            this.cargarProformas();
          });
        },
      });
    },

    cargarProformas() {
      this.cargando = true;
      this.ventaService.getAllProformas().then((response) => {
        this.proformas = response.proformas || [];
        this.cargando = false;
      });
    },
    imprimirProforma(id) {
      this.ventaService
        .imprimirVentaProforma(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
  },
  watch: {
    proformas() {
      this.totalRegistros = this.proformas.length;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>